import React, { useState, useEffect, useLayoutEffect } from "react"
import { Link, graphql } from "gatsby"
import ReactTooltip from "react-tooltip"
import marked from "marked"
import PageLayout from "../components/hbnfpagelayout"
import Head from "../components/head"
import speakerimg from "../images/speaker.png"
export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      signUpForUpdatesUrl
      registrationUrl
      registrationCtaText
      transparentHeader
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
      agenda {
        agendaNote
        timezoneNote
        calendarUrl
        tooltipText
        activeTab
        overview {
          overview
        }
        categories {
          title
          colorCode
        }
        schedule {
          categories {
            title
            colorCode
          }
          date(formatString: "MMMM D YYYY")
          note
          schedule {
            title
            time
            activity
            isSession
            hasTrainingLink
            session {
              title
              timing
              slug
              isSponsored
              details {
                details
              }
              category {
                title
                colorCode
              }
              speakers {
                name
                designation
                organisation
                bio {
                  bio
                }
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
        seoMetadata {
          title
          description {
            description
          }
        }
      }
    }
  }
`
const Agenda = props => {
  let registerUrl
  if (
    typeof window !== "undefined" &&
    props?.data?.contentfulEvents?.registrationUrl
  ) {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl?.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [loaded, setLoaded] = useState(false)
  const [selectedSpeaker, setSpeakerDetails] = useState()
  const [scrollPosition, setScrollPosition] = useState(0)
  useEffect(() => {
    if (typeof window !== "undefined") {
      setLoaded(true)
    }

    const close = e => {
      sessionStorage.setItem("scrollPosition", window.pageYOffset)
      setScrollPosition(window.pageYOffset)
    }
    setTimeout(() => {
      window.addEventListener("scroll", close)
    }, 1000)
    setTimeout(() => {
      window.scrollTo({
        top: Number(sessionStorage.getItem("scrollPosition")) ?? 0,
        left: 0,
        behavior: "smooth",
      })
      // window.scrollTo(0, Number(sessionStorage.getItem("scrollPosition")) ?? 0)
    }, 500)
    setCurrentActiveDate(
      sessionStorage.getItem("activeDate") === null
        ? Number(props?.data?.contentfulEvents?.agenda?.activeTab)
        : Number(sessionStorage.getItem("activeDate"))
    )
    sessionStorage.setItem(
      "activeDate",
      sessionStorage.getItem("activeDate") === null
        ? Number(props?.data?.contentfulEvents?.agenda?.activeTab)
        : Number(sessionStorage.getItem("activeDate"))
    )
    return () => window.removeEventListener("scroll", close)
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.target.id === "event_popup") {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("mousedown", close)
    return () => window.removeEventListener("mousedown", close)
  }, [])

  const [currentActiveDate, setCurrentActiveDate] = useState(
    typeof window !== "undefined"
      ? Number(sessionStorage.getItem("activeDate"))
      : 0
  )

  const [activeColors, setActiveColors] = useState(
    typeof window !== "undefined"
      ? sessionStorage.getItem("activeColors")?.split(",") || []
      : []
  )

  const handleSessionFilter = categoryColor => () =>
    setActiveColors(previous => {
      if (previous.includes(categoryColor)) {
        const colors = previous.filter(color => categoryColor !== color)
        sessionStorage.setItem("activeColors", colors.toString())
        return colors
      }
      const colors = [...previous, categoryColor]
      sessionStorage.setItem("activeColors", colors.toString())
      return colors
    })

  const onClickHandler = index => () => {
    setCurrentActiveDate(index)
    sessionStorage.setItem("activeDate", index)
  }

  // const getMarkdownText = agendaOverview => {
  //   if (
  //     agendaOverview &&
  //     (agendaOverview !== null || agendaOverview !== undefined)
  //   ) {
  //     var rawMarkup = marked(agendaOverview, { sanitize: true })
  //     return { __html: rawMarkup }
  //   }
  // }

  const showSpeakerDetails = (speakerDetails, colorCode) => e => {
    if (colorCode !== "#d43ffc") return colorCode
    e.preventDefault()
    setSpeakerDetails(speakerDetails)
    document.getElementById("event_popup").style.display = "flex"
  }

  const speakerPopupClose = () => {
    document.getElementById("event_popup").style.display = "none"
  }

  const colorCodes = [
    "#b12871",
    "#584bea",
    "#00ccc3",
    "#3793ff",
    "#746be1",
    "#d43ffc",
    "#f97700",
    "#ff2a37",
    "#01909c",
    "#01abe2",
    "#63d81c",
  ]
  const colorCodesMap = {
    "#b12871": "pink",
    "#584bea": "purple",
    "#00ccc3": "green",
    "#3793ff": "blue",
    "#746be1": "lavender",
    "#d43ffc": "violet",
    "#f97700": "orange",
    "#ff2a37": "red",
    "#01909c": "easternblue",
    "#01abe2": "irisblue",
    "#63d81c": "lima",
  }

  useLayoutEffect(() => {
    function loadScript() {
      var bz = document.createElement("script")
      bz.type = "text/javascript"
      bz.async = true
      bz.src = "https://organizer.bizzabo.com/widgets/agenda/agenda.js"
      var s = document.getElementsByTagName("script")[0]
      s.parentNode.insertBefore(bz, s)
    }
    loadScript()
  }, [])

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      signUpUrl={props?.data?.contentfulEvents?.signUpForUpdatesUrl}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={props?.data?.contentfulEvents?.agenda?.seoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.agenda?.seoMetadata?.description
            ?.description
        }
      />
      {/* {loaded && (
        <div className="agenda_body">
          {props?.data?.contentfulEvents?.agenda?.schedule === null &&
            props?.data?.contentfulEvents?.agenda?.overview && (
              <div
                className="agenda_overview"
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={getMarkdownText(
                  props?.data?.contentfulEvents?.agenda?.overview?.overview
                )}
              ></div>
            )}
          {props?.data?.contentfulEvents?.agenda?.schedule === null &&
            props?.data?.contentfulEvents?.agenda?.agendaNote && (
              <div className="agenda_title agenda_center">
                {props?.data?.contentfulEvents?.agenda?.agendaNote}
              </div>
            )}
          {props?.data?.contentfulEvents?.agenda?.schedule !== null && (
            <>
              {props?.data?.contentfulEvents?.agenda?.timezoneNote && (
                <div className="agenda_alltime">
                  {props?.data?.contentfulEvents?.agenda?.timezoneNote}
                </div>
              )}
              {props?.data?.contentfulEvents?.agenda?.calendarUrl && (
                <div className="agenda_cal">
                  <Link to={props?.data?.contentfulEvents?.agenda?.calendarUrl}>
                    iCal
                  </Link>{" "}
                  <span data-tip data-for="calenderTip">
                    <img src={require("../images/hoverimage.png")} />
                  </span>
                  <ReactTooltip id="calenderTip" place="top" effect="solid">
                    {props?.data?.contentfulEvents?.agenda?.tooltipText}
                  </ReactTooltip>
                </div>
              )}
              <div className="agenda_nav">
                <ul>
                  {props?.data?.contentfulEvents?.agenda?.schedule?.map(
                    (item, index) => {
                      return (
                        <li key={index} onClick={onClickHandler(index)}>
                          <a
                            style={{ cursor: "pointer" }}
                            className={currentActiveDate === index && "active"}
                          >
                            {item && item.date}
                          </a>
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
              {props?.data?.contentfulEvents?.agenda?.schedule.map(
                (item, index) => {
                  if (index === currentActiveDate && item.categories !== null)
                    return (
                      <div className="agenda_category">
                        <div className="agenda_category_title">Category</div>
                        {item?.categories?.map(item => (
                          <div
                            className={`agenda_category_type ${
                              activeColors.includes(item.colorCode)
                                ? colorCodesMap[item.colorCode] + "border"
                                : colorCodesMap[item.colorCode]
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={handleSessionFilter(item.colorCode)}
                          >
                            {item?.title}
                          </div>
                        ))}
                      </div>
                    )
                }
              )}

              {props?.data?.contentfulEvents?.agenda?.schedule?.map(
                (item, index) => {
                  if (index === currentActiveDate)
                    return (
                      <React.Fragment>
                        {item &&
                          item.schedule &&
                          item.schedule.map((session, sessionIndex) => {
                            return (
                              <React.Fragment>
                                {session && !session.isSession && (
                                  <div
                                    className="agenda_event"
                                    key={sessionIndex}
                                  >
                                    {session?.time && (
                                      <div className="agenda_event_time">
                                        {session.time}
                                      </div>
                                    )}
                                    <div
                                      className="agenda_event_action"
                                      style={{
                                        width:
                                          session?.time === null
                                            ? "100%"
                                            : "50%",
                                      }}
                                    >
                                      {session.hasTrainingLink && (
                                        <Link to={session.hasTrainingLink}>
                                          {session.activity}
                                        </Link>
                                      )}
                                      {!session.hasTrainingLink && (
                                        <>{session.activity}</>
                                      )}
                                    </div>
                                  </div>
                                )}

                                {session && session.isSession && (
                                  <div className="agenda_event">
                                    <div className="agenda_event_time">
                                      {session.time}
                                    </div>
                                    <div className="agenda_event_action">
                                      {session.activity}
                                    </div>
                                    <div className="agenda_event_list_body">
                                      {colorCodes.map(color => (
                                        <div
                                          className="agenda_event_list_container"
                                          style={{
                                            display:
                                              activeColors.length === 0 ||
                                              !activeColors.includes(color)
                                                ? "flex"
                                                : "none",
                                          }}
                                        >
                                          {session?.session
                                            .filter(
                                              sessionItem =>
                                                sessionItem.category
                                                  .colorCode === color
                                            )
                                            .map(
                                              (
                                                sessionItem,
                                                sessionItemIndex
                                              ) => (
                                                <Link
                                                  to={
                                                    sessionItem?.details !==
                                                    null
                                                      ? `/sessions/${sessionItem?.slug}`
                                                      : ""
                                                  }
                                                  className={`agenda_event_list ${colorCodesMap[color]}`}
                                                  style={{
                                                    pointerEvents:
                                                      sessionItem?.details ===
                                                      null
                                                        ? "none"
                                                        : "",
                                                    backgroundImage:
                                                      sessionItem?.details ===
                                                      null
                                                        ? "none"
                                                        : "",
                                                  }}
                                                  key={sessionItemIndex}
                                                >
                                                  <p>{sessionItem?.timing}</p>
                                                  <h5>
                                                    {sessionItem?.title}{" "}
                                                    {sessionItem?.isSponsored && (
                                                      <span>(Sponsored)</span>
                                                    )}
                                                  </h5>
                                                  {sessionItem?.speakers?.map(
                                                    (speaker, speakerIndex) => {
                                                      return (
                                                        <p
                                                          onClick={showSpeakerDetails(
                                                            speaker,
                                                            sessionItem.category
                                                              .colorCode
                                                          )}
                                                          style={{
                                                            pointerEvents:
                                                              "all",
                                                          }}
                                                        >
                                                          {speaker?.name}{" "}
                                                          {`(${speaker?.organisation})`}
                                                        </p>
                                                      )
                                                    }
                                                  )}
                                                </Link>
                                              )
                                            )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            )
                          })}
                        {item?.note !== null && (
                          <div
                            className="agenda_note"
                            style={{
                              marginTop: item?.schedule === null ? "0px" : "",
                            }}
                          >
                            {item?.note}
                          </div>
                        )}
                      </React.Fragment>
                    )
                }
              )}
              <div className="agenda_warning">
                Schedule is subject to change
              </div>
            </>
          )}
        </div>
      )}
      <div className="event_popup" id="event_popup">
        <div className="event_popup_body">
          <div className="event_popup_close">
            <span onClick={speakerPopupClose}>X</span>
          </div>
          <div className="event_popup_speaker">
            <div className="speaker_photo">
              <img
                src={
                  selectedSpeaker?.image?.file?.url
                    ? selectedSpeaker?.image?.file?.url
                    : speakerimg
                }
                alt="speakerheadshot"
              />
            </div>
            <div className="speaker_data">
              <div className="speaker_name">{selectedSpeaker?.name}</div>
              <div className="speaker_info">
                {selectedSpeaker?.designation && (
                  <>{selectedSpeaker?.designation},</>
                )}{" "}
                {selectedSpeaker?.organisation && (
                  <>{selectedSpeaker?.organisation}</>
                )}
              </div>
            </div>
          </div>
          <div
            className="event_popup_info"
            style={{ whiteSpace: "pre-wrap", marginTop: "15px" }}
          >
            {selectedSpeaker?.bio?.bio}
          </div>
        </div>
      </div> */}
      {props?.data?.contentfulEvents?.slug === "kafka-summit-london-2024" && (
        <>
          {props?.data?.contentfulEvents?.agenda?.overview?.overview && (
            <div className="agenda_body">
              <div
                className="agenda_overview"
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: marked.parse(
                    props?.data?.contentfulEvents?.agenda?.overview?.overview ??
                      ""
                  ),
                }}
              ></div>
            </div>
          )}

          <div
            name="bizzabo-web-agenda"
            data-unique-name="559905"
            data-internal-website-base-url="https://events.bizzabo.com/559905"
          ></div>
        </>
      )}
      {props?.data?.contentfulEvents?.slug ===
        "kafka-summit-bangalore-2024" && (
        // <div className="agenda_body">
        //   <div className="agenda_title agenda_center">Coming Soon</div>
        // </div>
        <>
          {props?.data?.contentfulEvents?.agenda?.overview?.overview && (
            <div className="agenda_body">
              <div
                className="agenda_overview"
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: marked.parse(
                    props?.data?.contentfulEvents?.agenda?.overview?.overview ??
                      ""
                  ),
                }}
              ></div>
            </div>
          )}
          <div
            name="bizzabo-web-agenda"
            data-unique-name="573863"
            data-internal-website-base-url="https://events.bizzabo.com/573863"
          ></div>
        </>
      )}
    </PageLayout>
  )
}
export default Agenda
